import React from 'react';
import useWebSocketDataStore from "../Store/WebSocketDataStore.jsx";

const SeriesToggleButton = ({ seriesKey, label, className }) => {
  const { toggles, setToggle } = useWebSocketDataStore(state => ({
    toggles: state.toggles,
    setToggle: state.setToggle
  }));

  const handleClick = () => {
    setToggle(seriesKey, !toggles[seriesKey]);
  };

  return (
    <label className={`checkbox-container ${className}`}>
      {label}
      <input
        type="checkbox"
        checked={toggles[seriesKey]}
        onChange={handleClick}
      />
      <span className="checkmark"></span>
    </label>
  );
};

export default SeriesToggleButton;





// import React from 'react';
// import useWebSocketDataStore from "../Store/WebSocketDataStore.jsx"
//
// const SeriesToggleButton = ({ seriesKey, label, className }) => {
//   const { toggles, setToggle } = useWebSocketDataStore(state => ({
//     toggles: state.toggles,
//     setToggle: state.setToggle
//   }));
//
//   const handleClick = () => {
//     setToggle(seriesKey, !toggles[seriesKey]);
//   };
//
//   return (
//     <button
//       onClick={handleClick}
//       className={`toggle-button ${className} ${toggles[seriesKey] ? 'active' : ''}`}
//       style={{
//         backgroundColor: toggles[seriesKey] ? '#B18800' : '#333' , // Change background color when toggled
//       }}
//     >
//       {label}
//     </button>
//   );
// };
//
// export default SeriesToggleButton;