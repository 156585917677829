import React, { useEffect } from 'react';
import useWebSocketDataStore from "../Store/WebSocketDataStore.jsx";
import moment from 'moment-timezone';

const WebSocketClient = () => {
  const { appendData, setOptionsData } = useWebSocketDataStore();

  useEffect(() => {
    let socket;
    const wsURL = process.env.REACT_APP_WEBSOCKET_URL; //For production
    // const wsURL = 'ws://localhost:3001'; // For local testing
    const seenTimestamps = new Set(); // Initialize a Set to track seen timestamps for GREEKDATA

    const connectWebSocket = () => {
      socket = new WebSocket(wsURL);

      socket.addEventListener('open', () => {
        console.log('Connected to WebSocket');
      });

      socket.addEventListener('message', (event) => {
        const newDataArray = JSON.parse(event.data);
        // console.log('Data received:', newDataArray);

        const adjustTimeToLocalUnix = (estTime) => {
          const cleanedEstTime = estTime.replace('Z', '');
          const estMoment = moment.tz(cleanedEstTime, "YYYY-MM-DDTHH:mm:ss.SSS", "America/New_York");
          const estUnixTimestamp = estMoment.unix();

          const adjustUnixTimeToLocal = (unixTimestamp) => {
            const date = new Date(unixTimestamp * 1000);
            const localOffset = date.getTimezoneOffset() * 60; // Offset in seconds
            return unixTimestamp - localOffset;
          };

          const localUnixTimestamp = adjustUnixTimeToLocal(estUnixTimestamp);
          return localUnixTimestamp;
        };

        const greekData = newDataArray.filter(item => item.source === 'GREEKDATA');
        if (greekData.length > 0) {
          const uniqueGreekData = greekData.reduce((acc, item) => {
            const adjustedTime = adjustTimeToLocalUnix(item.time);
            if (!seenTimestamps.has(adjustedTime)) {
              seenTimestamps.add(adjustedTime);
              acc.push({
                ...item,
                time: adjustedTime // Store the time as a Unix timestamp
              });
            }
            return acc;
          }, []);

          appendData(uniqueGreekData); // Update the store with adjusted and unique GREEKDATA
        }

        const optionsData = newDataArray.filter(item => item.source === 'GEXLEVELS');
        if (optionsData.length > 0) {
          const adjustedOptionsData = optionsData.map(item => ({
            ...item,
            timestamp: adjustTimeToLocalUnix(item.timestamp) // Adjust time to local Unix timestamp
          }));
          setOptionsData(adjustedOptionsData); // Update the store with GEXLEVELS directly
        }
      });

       socket.addEventListener('close', () => {
         // Attempt to reconnect after 3 seconds if the connection closes
         setTimeout(connectWebSocket, 3000);
       });
     };

     connectWebSocket();

     return () => {
       if (socket) {
         socket.close();
       }
     };
   }, [appendData, setOptionsData]);

   return null;
 };

 export default WebSocketClient;






























