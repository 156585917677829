import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

// Function to initialize variable toggles
const initializeVariableToggles = (currentToggles, newData) => {
  const defaultVisibility = {
    total_gex: true,
    total_call_volume_gamma: true,
    total_put_volume_gamma: true,
    total_call_volume_vega: false,
    total_put_volume_vega: false,
    total_call_volume_delta: false,
    total_put_volume_delta: false,
    total_call_volume_theta: false,
    total_put_volume_theta: false,
    // Additional non-LR toggles as needed
  };

  const updatedToggles = { ...currentToggles };

  newData.forEach(item => {
    Object.keys(item).forEach(key => {
      if (updatedToggles[key] === undefined) {
        updatedToggles[key] = defaultVisibility[key] !== undefined ? defaultVisibility[key] : true;
      }
    });
  });

  return updatedToggles;
};



const useWebSocketDataStore = create(devtools(set => ({
  data: [],
  toggles: { ...initializeVariableToggles({}, []) },
  regressionLines: {},
  callData: [], // New state for call options
  putData: [], // New state for put options
  correlations: {
    times: [],
    correlations20: [],
    correlations30: [],
    correlations40: [],
  },

  setData: newData => {
    set(state => {
      const updatedToggles = {  ...initializeVariableToggles(state.toggles, newData) };
      return {
        data: newData,
        toggles: updatedToggles,
      };
    });
  },

  addData: newDataRow => set(state => {
    const exists = state.data.some(item => item.time === newDataRow.time);
    let newData = exists ? state.data : [...state.data, newDataRow];
    const updatedToggles = {  ...initializeVariableToggles(state.toggles, newData) };
    return {
      data: newData,
      toggles: updatedToggles,
    };
  }),

  appendData: newDataArray => set(state => {
    const newData = [...state.data, ...newDataArray];
    const updatedToggles = {  ...initializeVariableToggles(state.toggles, newData) };
    return {
      data: newData,
      toggles: updatedToggles,
    };
  }),

  setToggle: (key, value) => set(state => ({
    ...state,
    toggles: { ...state.toggles, [key]: value },
  })),


  // Method to handle incoming options data
  setOptionsData: newOptionsData => set(() => {
    // Filter and sort call options data, then take the top 10
    const newCallData = newOptionsData
      .filter(item => item.option_type === 'call')
      .sort((a, b) => parseFloat(b.volume_gamma) - parseFloat(a.volume_gamma))
      .slice(0, 10); // Keep only the top 10 call options

    // Filter and sort put options data, then take the top 10
    const newPutData = newOptionsData
      .filter(item => item.option_type === 'put')
      .sort((a, b) => parseFloat(b.volume_gamma) - parseFloat(a.volume_gamma))
      .slice(0, 10); // Keep only the top 10 put options

    return {
      callData: newCallData,
      putData: newPutData,
    };
  }),

  // Method to update the correlations
  setCorrelations: (newCorrelations) => {
    // console.log("SPXGamma Trend:", newCorrelations);
    set(() => ({
      correlations: newCorrelations,
    }));
  },
})));

export default useWebSocketDataStore;









