import React, { useState, useEffect } from 'react';
import useWebSocketDataStore from '../Store/WebSocketDataStore.jsx';
import './Tables.css';

const TablesComponent = () => {
  const { callData, putData } = useWebSocketDataStore();
  const [callTableData, setCallTableData] = useState([]);
  const [putTableData, setPutTableData] = useState([]);

  useEffect(() => {

    // Directly set the received callData and putData to state
    setCallTableData(callData);

    setPutTableData(putData);

  }, [callData, putData]);


  return (
    <div className="tables-container">
      <div className="table">
        <h2>Calls</h2>
        <table>
          <thead>
            <tr>
              <th>Strike</th>
              <th>SPX Gex</th>
              <th>Volume</th>
            </tr>
          </thead>
          <tbody>
            {callTableData.map((item, index) => (
              <tr key={index}>
                <td>{item.strike}</td>
                <td>{item.volume_gamma}</td>
                <td>{item.volume}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="table">
        <h2>Puts</h2>
        <table>
          <thead>
            <tr>
              <th>Strike</th>
              <th>SPX Gex</th>
              <th>Volume</th>
            </tr>
          </thead>
          <tbody>
            {putTableData.map((item, index) => (
              <tr key={index}>
                <td>{item.strike}</td>
                <td>{item.volume_gamma}</td>
                <td>{item.volume}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TablesComponent;